
  import { defineComponent, ref, onMounted, computed, Ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import useProducts from "@/composables/useProducts";
  import useProductBundle from "@/composables/useProductBundle";
  import { ProductUnitFilter, ProductsFilter } from "@/models";
  import {
    IPagedResultDtoOfGetProductBundleForViewDto,
    CreateOrEditProductBundleDto,
    ICreateOrEditProductBundleDto,
    ICreateOrEditProductBundleDefinitionDto,
  } from "@/shared/service-proxies/generated-proxies";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { useToast } from "vue-toast-notification";
  import { Actions } from "@/store/enums/StoreEnums";
  import { FileUploadDTo } from "@/models";

  export default defineComponent({
    name: "CreateOrEditProduct",
    components: {},
    setup() {
      const { products, getProducts, productUnits, getProductUnits } =
        useProducts();
      const {
        productBundle,
        updateOrCreateProductBundle,
        getProductBundle,
        uploadImage,
      } = useProductBundle();
      const route = useRoute();
      const router = useRouter();
      const saving = ref(false);
      const productBundleId = computed(() => route.query.id);
      const store = useStore();
      const breadcrumbs = ref({
        title: "Product Bundle Create",
        breadcrumbs: ["Product", "Create"],
      });
      const loaded = ref(false);
      const prodUnit = ref({});
      const singldef: any = ref({
        quantity: 0,
        product: {},
      });

      const prodef: any = ref([]);

      const defaultFilter = ref({
        filter: "",
        skipCount: 0,
        isActiveFilter: -1,
        maxResultCount: 1000,
      } as unknown as ProductsFilter);
      const productUnitFilter = ref({
        skipCount: 0,
        maxResultCount: 20,
      } as unknown as ProductUnitFilter);
      const rules = ref({
        name: [
          {
            required: true,
            message: "Name is required",
            trigger: "change",
          },
        ],
        displayName: [
          {
            required: true,
            message: "Display name is required",
            trigger: "change",
          },
        ],
        description: [
          {
            required: true,
            message: "Description is required",
            trigger: "change",
          },
        ],
        unitPrice: [
          {
            required: true,
            message: "Unitprice is required",
            trigger: "change",
          },
        ],
      });

      const defaultData = ref({
        // id:null,
        name: "",
        displayName: "",
        description: "",
        unitPrice: "",
        area: "",
        productBundleDefinition: [],
      }) as unknown as ICreateOrEditProductBundleDto;

      let formData = ref<ICreateOrEditProductBundleDto>(defaultData);

      const imgUploaded = ref(false);
      const fileUploadImg = ref("");
      const fileUpload = ref();
      const fileUploadImage = ref({
        name: "",
        size: "",
        type: "",
      } as unknown as FileUploadDTo);
      const config = store.getters.getConfigurations;
      const permissions = config.result.auth.grantedPermissions;

      const addProduct = async () => {
        if (singldef.value.quantity < 1) {
          useToast().error("Quantity is required");
          return;
        }
        if (Object.keys(singldef.value.product).length < 1) {
          useToast().error("Product is required");
          return;
        }
        if (Object.keys(prodUnit.value).length < 1) {
          useToast().error("Product unit is required");
          return;
        }

        prodef.value.push({ ...singldef.value, prodUnit: prodUnit.value });

        singldef.value.quantity = "";
        singldef.value.product = {};
        prodUnit.value = {};
      };

      const removeItem = (index) => {
        prodef.value.splice(index, 1);
      };

      const submit = async () => {
        if (formData.value.name.length < 1) {
          useToast().error("Name is required");
          return;
        }

        if (formData.value.displayName.length < 1) {
          useToast().error("Display name is required");
          return;
        }
        if (formData.value.unitPrice < 1) {
          useToast().error("Unit price is required");
          return;
        }
        if (prodef.value.length < 1) {
          useToast().error("Product definition is required");
          return;
        }
        saving.value = true;

        prodef.value.forEach((item) => {
          var defs: any = {
            quantity: item.quantity,
            productId: item.product.product.id,
            productUnitId: item.prodUnit.productUnit.id,
          };
          formData.value.productBundleDefinition?.push({ ...defs });
        });

        await updateOrCreateProductBundle(
          formData.value as unknown as CreateOrEditProductBundleDto,
          undefined
        );
        const error = store.getters.getErrors;

        if (!error) {
          Swal.fire({
            text: productBundleId.value
              ? "Product bundle updated successfully"
              : "Product bundle created successfully",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Okay!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            },
          });
          window.setTimeout(() => {
            router.push({ name: "product-bundles" });
          }, 1500);
        } else {
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        saving.value = false;
      };

      const getProductsData = async () => {
        await getProducts(defaultFilter.value as unknown as ProductsFilter);
      };

      const selectForFile = (event) => {
        imgUploaded.value = true;
        var file = event.target.files[0];
        fileUpload.value = file;
        fileUploadImage.value = file;
        fileUploadImg.value = URL.createObjectURL(file);
      };

      const uploadFile = async () => {
        const formData: FormData = new FormData();
        formData.append("file", fileUpload.value, fileUpload.value.name);
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        formData.append("productBundleId", productBundleId.value as string);
        await uploadImage(formData);
        window.location.reload();
      };

      const clearSelection = () => {
        imgUploaded.value = false;
        fileUploadImg.value = "";
        fileUploadImage.value = {} as unknown as FileUploadDTo;
      };

      const getData = async () => {
        await getProductsData();
        await getProductUnits(
          productUnitFilter.value as unknown as ProductUnitFilter
        );

        if (productBundleId.value) {
          await getProductBundle(productBundleId.value as string);

          if (productBundle.value) {
            productBundle.value.productBundleDefinitions?.forEach(
              (item: any) => {
                var unit = productUnits.value.find(
                  (item2) =>
                    item2.productUnit.id ==
                    item.productBundleDefinition.productUnitId
                );
                prodef.value.push({
                  ...item.productBundleDefinition,
                  prodUnit: unit,
                });
              }
            );
            formData.value = {
              ...productBundle.value.productBundle,
              id: productBundleId.value,
              productBundleDefinition: [],
            } as unknown as ICreateOrEditProductBundleDto;

            formData.value.id = productBundleId.value as string;
            prodef.value.forEach((item, index) => {
              var hd = products.value.find(
                (item2) => item2.product.id == item.productId
              );
              item.product = {
                product: hd?.product,
              };
            });
          }
          loaded.value = true;
        }
      };

      onMounted(async () => {
        if (productBundleId.value) {
          breadcrumbs.value = {
            title: "Product Bundle Edit",
            breadcrumbs: ["Product Bundle", "Edit"],
          };
        } else {
          breadcrumbs.value = {
            title: "Product Bundle Create",
            breadcrumbs: ["Product Bundle", "Create"],
          };
        }

        await getData();
      });

      return {
        prodUnit,
        productUnits,
        loaded,
        imgUploaded,
        fileUploadImg,
        fileUploadImage,
        productBundle,
        productBundleId,
        permissions,
        uploadFile,
        selectForFile,
        clearSelection,
        addProduct,
        formData,
        saving,
        route,
        products,
        rules,
        submit,
        breadcrumbs,
        prodef,
        singldef,
        removeItem,
      };
    },
  });
